import _objectSpread from "E:/AirVenture/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from "moment-timezone";
import { QUERY } from "@/core/services/store/request.module";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  data: function data() {
    return {
      product: 0,
      dataLoading: true,
      summary: "this_week",
      summaryFilter: [{
        text: "This Week",
        value: "this_week"
      }, {
        text: "Previous Week",
        value: "previous_week"
      }, {
        text: "This Month",
        value: "this_month"
      }, {
        text: "Previous Month",
        value: "previous_month"
      },
      /*{ text: "This Quarter", value: "this_quarter" },
      { text: "Previous Quarter", value: "previous_quarter" },*/
      {
        text: "This Year",
        value: "this_year"
      }, {
        text: "Previous Year",
        value: "previous_year"
      }],
      chartOptions: {
        chart: {
          id: "product-summary"
        },
        xaxis: {
          categories: []
        },
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "'Poppins', Helvetica, Arial, sans-serif"
          }
        }
      },
      series: [],
      tOneFields: [{
        title: "Name",
        key: "name",
        child: false
      },
      /*{
        title: "Reference",
        key: "reference",
        child: false
      },*/
      {
        title: "Product #",
        key: "barcode",
        child: false
      },
      /*{
        title: "SKU",
        key: "sku",
        child: false
      },
      {
        title: "UPC",
        key: "upc",
        child: false
      },
      {
        title: "EAN",
        key: "ean",
        child: false
      },
      {
        title: "ISBN",
        key: "isbn",
        child: false
      },*/
      {
        title: "Part Number",
        key: "part_number",
        child: false
      }, {
        title: "Serial Number",
        key: "serial_number",
        child: false
      },
      /*{
        title: "HSN Code",
        key: "hsn_code",
        child: false
      },*/
      {
        title: "Description",
        key: "description",
        child: false
      },
      /*{
        title: "Supplier",
        key: "supplier",
        child: "display_name",
      },*/
      {
        title: "Brand",
        key: "brand",
        child: "text"
      }, {
        title: "Manufacturer",
        key: "manufacturer",
        child: "text"
      }, {
        title: "Category",
        key: "category",
        child: "text"
      }, {
        title: "Unit",
        key: "unit",
        child: "text"
      }]
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: function _default() {
        return new Object();
      }
    }
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.product = this.lodash.toSafeInteger(param.id);
        this.getSummary(false);
      }
    }
  },
  methods: {
    getSummary: function getSummary(scrollBottom) {
      var _this = this;

      if (_this.product > 0) {
        _this.dataLoading = true;
        _this.series = [];

        _this.$store.dispatch(QUERY, {
          url: "product/" + _this.product + "/summary",
          data: {
            filter: _this.summary
          }
        }).then(function (_ref) {
          var data = _ref.data;
          var categories = Object.values(data.categories);

          if (_this.lodash.isEmpty(categories) === false && _this.lodash.isArray(categories)) {
            _this.chartOptions = _objectSpread(_objectSpread({}, _this.chartOptions), {
              xaxis: {
                categories: categories.map(function (row) {
                  if (_this.summary == "this_year" || _this.summary == "previous_year") {
                    return moment(row).format("MMMM, YYYY");
                  }

                  return moment(row).format("Do, MMMM, YYYY");
                })
              }
            });

            if (_this.lodash.isEmpty(data.series) === false) {
              if (_this.lodash.isEmpty(data.series.quotation_counted) === false) {
                _this.series.push({
                  name: "Quotation",
                  data: Object.values(data.series.quotation_counted)
                });
              }

              if (_this.lodash.isEmpty(data.series.invoice_counted) === false) {
                _this.series.push({
                  name: "Invoice",
                  data: Object.values(data.series.invoice_counted)
                });
              }

              if (_this.lodash.isEmpty(data.series.job_counted) === false) {
                _this.series.push({
                  name: "Job",
                  data: Object.values(data.series.job_counted)
                });
              }
            }
          }
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.dataLoading = false;

          if (_this.lodash.isEmpty(_this.series) === false && scrollBottom) {
            var objDiv = _this.$refs["sidebarScrollbar"].$el;

            _this.$nextTick(function () {
              objDiv.scrollTop = objDiv.scrollHeight;
            });
          }
        });
      }
    }
  },
  mounted: function mounted() {
    this.getSummary();
  }
};