import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from "moment-timezone";
import { GET, PATCH, QUERY } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  mixins: [CommonMixin],
  name: "adjustment-internal-list",
  data: function data() {
    return {
      parent: 0,
      dates: [],
      statusList: [],
      dataLoading: true,
      detailDialog: false,
      momentObject: moment,
      customerList: [],
      dataRows: [],
      filter: {
        status: "all",
        search: null
      },
      timeout: null,
      timeoutLimit: 500,
      adjustmentDetail: new Object()
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: function _default() {
        return new Object();
      }
    }
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);

        if (this.parent > 0) {
          this.getAllAdjustments();
        }
      }
    }
  },
  components: {
    Barcode: Barcode,
    Dialog: Dialog,
    ListingTable: ListingTable,
    TableActivity: TableActivity
  },
  methods: {
    updateMoreAction: function updateMoreAction(action) {
      var _this = this;

      switch (action) {
        case "mark_as_approved":
          _this.patchAdjustment({
            adjustments: [_this.adjustmentDetail.id],
            status: 2
          });

          break;

        case "mark_as_adjusted":
          _this.patchAdjustment({
            adjustments: [_this.adjustmentDetail.id],
            status: 3
          });

          break;
      }
    },
    patchAdjustment: function patchAdjustment(data) {
      var _this = this;

      _this.$store.dispatch(PATCH, {
        url: "product/adjustment",
        data: data
      }).then(function () {
        _this.getAllAdjustments();

        _this.detailDialog = false;
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    getImageUrl: function getImageUrl(image) {
      if (image) {
        return image.file.url;
      }

      return null;
    },
    showDetail: function showDetail(row) {
      var _this = this;

      _this.$store.dispatch(GET, {
        url: "product/adjustment/" + row.id
      }).then(function (_ref) {
        var data = _ref.data;
        _this.detailDialog = true;
        _this.adjustmentDetail = data;
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    clearFilter: function clearFilter(field) {
      var _this = this;

      switch (field) {
        case "search":
          _this.filter.search = null;
          break;

        case "dates":
          _this.dates = [];
          break;
      }

      _this.getAllAdjustments();
    },
    getAllAdjustments: function getAllAdjustments() {
      var _this = this;

      var filter = {
        per_page: 10,
        current_page: 1,
        product: _this.detail.id,
        status: _this.filter.status || "all",
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined
      };

      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit("update:error", InitializeError("End Date must be after Start Date."));
          return false;
        }
      } else {
        filter.dates = undefined;
      }

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store.dispatch(QUERY, {
          url: "product/adjustment",
          data: filter
        }).then(function (_ref2) {
          var data = _ref2.data;
          _this.dataRows = data.rows;
          _this.statusList = data.status_list;
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.dataLoading = false;
        });
      }, _this.timeoutLimit);
    }
  },
  computed: {
    adjustmentMoreAction: function adjustmentMoreAction() {
      var result = new Array();

      if (this.lodash.isEmpty(this.adjustmentDetail) === false) {
        if (this.adjustmentDetail.status < 2) {
          result.push({
            title: "Mark as Approved",
            action: "mark_as_approved",
            icon: "mdi-check-all",
            disabled: false
          });
        }

        if (this.adjustmentDetail.status < 3) {
          result.push({
            title: "Mark as Adjusted",
            action: "mark_as_adjusted",
            icon: "mdi-check-all",
            disabled: false
          });
        }
      }

      return result;
    },
    adjustmentItems: function adjustmentItems() {
      return this.adjustmentDetail ? this.adjustmentDetail.line_items : [];
    },
    dialogWidth: function dialogWidth() {
      return this.lodash.toSafeInteger(document.body.clientWidth / 100 * 50);
    }
  }
};