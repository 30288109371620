import _objectSpread from "E:/AirVenture/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH } from "@/core/services/store/request.module";
import Overview from "@/view/pages/product/detail/Overview";
import Warranty from "@/view/pages/product/detail/Warranty";
import Tickets from "@/view/pages/partials/Detail/Tickets";
import Quotations from "@/view/pages/partials/Detail/Quotation";
import Invoices from "@/view/pages/partials/Detail/Invoice";
import ProductAdjustment from "@/view/pages/product/detail/ProductAdjustment";
import Images from "@/view/pages/partials/Detail/Image";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
export default {
  data: function data() {
    return {
      detail: new Object(),
      productMoreAction: [
      /*{
        title: "Convert to Equipment",
        action: "convert_to_equipment",
        icon: "mdi-power-plug-outline",
        disabled: false,
      },*/
      {
        title: "Duplicate",
        action: "duplicate",
        icon: "mdi-content-duplicate",
        disabled: false
      }, {
        title: "Mark as Active",
        action: "mark_as_active",
        icon: "mdi-check-all",
        disabled: false
      }, {
        title: "Mark as In-Active",
        action: "mark_as_inactive",
        icon: "mdi-check-all",
        disabled: false
      }],
      tabs: [{
        title: "Overview",
        icon: "mdi-phone",
        key: "product",
        permission: "product",
        disabled: false
      },
      /*{
        title: "Adjustment",
        icon: "mdi-account-multiple",
        key: "product-adjustment",
        permission: "product-adjustment",
        disabled: false,
      },*/
      {
        title: "Images",
        icon: "mdi-phone",
        key: "image",
        permission: "product",
        disabled: false
      },
      /*{
        title: "Warranty",
        icon: "mdi-credit-card",
        key: "warranty",
        permission: "warranty",
        disabled: false,
      },
      {
        title: "Jobs",
        icon: "mdi-credit-card",
        key: "job",
        permission: "job",
        disabled: false,
      },
      {
        title: "Quotations",
        icon: "mdi-account-multiple",
        key: "quotation",
        permission: "quotation",
        disabled: false,
      },
      {
        title: "Invoices",
        icon: "mdi-account-multiple",
        key: "invoice",
        permission: "invoice",
        disabled: false,
      },*/
      {
        title: "History",
        icon: "mdi-account-multiple",
        key: "history",
        permission: "history",
        disabled: false
      }],
      formValid: true,
      formLoading: false,
      pageLoading: true,
      //productTab: null,
      product: 0
    };
  },
  components: {
    DetailTemplate: DetailTemplate,
    Overview: Overview,
    Warranty: Warranty,
    Tickets: Tickets,
    Images: Images,
    Quotations: Quotations,
    Invoices: Invoices,
    ProductAdjustment: ProductAdjustment,
    InternalHistoryDetail: InternalHistoryDetail
  },
  methods: {
    patchProductParams: function patchProductParams(status) {
      var _this = this;

      _this.pageLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "product",
        data: {
          status: status,
          products: [_this.product]
        }
      }).then(function () {
        _this.getProductDetail();
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    updateMoreAction: function updateMoreAction(action) {
      var _this = this;

      switch (action) {
        case "convert_to_equipment":
          _this.convertToEquipment();

          break;

        case "duplicate":
          _this.duplicateProduct();

          break;

        case "mark_as_active":
          _this.patchProductParams(1);

          break;

        case "mark_as_inactive":
          _this.patchProductParams(0);

          break;
      }
    },
    convertToEquipment: function convertToEquipment() {
      this.$router.push(this.getDefaultRoute("equipment.create", {
        query: {
          product: this.product
        }
      }));
    },
    duplicateProduct: function duplicateProduct() {
      this.$router.push(this.getDefaultRoute("product.create", {
        query: {
          duplicate: this.product
        }
      }));
    },
    getProductDetail: function getProductDetail() {
      var _this = this;

      _this.pageLoading = true;

      _this.$store.dispatch(GET, {
        url: "product/" + _this.product
      }).then(function (_ref) {
        var data = _ref.data;
        _this.detail = data;

        _this.$store.dispatch(SET_BREADCRUMB, [{
          title: "Product",
          route: "product"
        }, {
          title: "Detail"
        }, {
          barcode: data.barcode
        }]);
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
      });
    }
  },
  mounted: function mounted() {
    var _this = this;

    _this.getProductDetail();

    _this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Product",
      route: "product"
    }, {
      title: "Detail"
    }]);
  },
  created: function created() {
    var _this = this;

    _this.product = _this.$route.params.id;

    if (!_this.product || _this.product <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.productTab = _this.$route.query.tab;
    }
  },
  computed: {
    productTab: {
      set: function set(val) {
        var query = _objectSpread({}, this.$route.query);

        query.tab = val;

        if (this.productTab != val) {
          this.$router.push({
            query: query
          });
        }
      },
      get: function get() {
        return this.$route.query.tab || "product";
      }
    }
  }
};