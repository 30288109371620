import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { PATCH, DELETE } from "@/core/services/store/request.module";
export default {
  props: {
    type: {
      type: String,
      required: true,
      default: "product"
    },
    parent: {
      type: Number,
      required: true,
      default: 0
    },
    isPageLoading: {
      type: Boolean,
      required: true,
      default: false
    },
    images: {
      type: Array,
      required: true,
      default: function _default() {
        return new Array();
      }
    }
  },
  data: function data() {
    return {
      pageLoading: false,
      primaryLoading: false,
      deleteLoading: false,
      isPrimary: false,
      selectedImage: null,
      selectedImageId: 0,
      itemImages: new Array()
    };
  },
  watch: {
    images: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.itemImages = param;
        this.setFirstImage();
      }
    }
  },
  mounted: function mounted() {
    this.setFirstImage();
  },
  methods: {
    deleteImage: function deleteImage() {
      var _this = this;

      _this.pageLoading = true;
      _this.deleteLoading = true;

      _this.$store.dispatch(DELETE, {
        url: "file-manager/delete/".concat(_this.selectedImageId)
      }).then(function () {
        var index = _this.lodash.findIndex(_this.itemImages, function (row) {
          return row.id == _this.selectedImageId;
        });

        if (index >= 0) {
          _this.itemImages.splice(index, 1);
        }
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
        _this.deleteLoading = false;
      });
    },
    setAsPrimary: function setAsPrimary() {
      var _this = this;

      _this.pageLoading = true;
      _this.primaryLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "".concat(_this.type, "/").concat(_this.parent, "/image/").concat(_this.selectedImageId, "/primary")
      }).then(function (_ref) {
        var data = _ref.data;
        _this.itemImages = data;

        _this.setFirstImage();
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
        _this.primaryLoading = false;
      });
    },
    setFirstImage: function setFirstImage() {
      var _this = this;

      var firstImage = _this.lodash.head(_this.itemImages);

      if (_this.lodash.isEmpty(firstImage) === false) {
        _this.selectedImage = firstImage.file.url;
        _this.selectedImageId = firstImage.id;
        _this.isPrimary = firstImage.primary;
      }
    },
    setImage: function setImage(row) {
      this.selectedImage = row.file.url;
      this.selectedImageId = row.id;
      this.isPrimary = row.primary;
    }
  }
};